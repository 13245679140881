@font-face {
  font-family: 'Ubuntu';
  src: url('/fonts/Ubuntu-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('/fonts/Ubuntu-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('/fonts/Ubuntu-Light.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('/fonts/Ubuntu-LightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('/fonts/Ubuntu-Medium.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('/fonts/Ubuntu-MediumItalic.ttf');
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('/fonts/Ubuntu-Bold.ttf');
  font-weight: 900;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('/fonts/Ubuntu-BoldItalic.ttf');
  font-weight: 900;
  font-style: italic;
}
