@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

/* VARIABLES */

body {
  --main-color: #06bcc1;
  --text-color: rgba(255, 255, 255, 0.9);
  --text-color-light: rgba(255, 255, 255, 0.5);
  --border-color: rgba(255, 255, 255, 0.1);
  --bg-color: #121212;
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 0.5em 0;
}

.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: 0.25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color);
  font-size: 50%;
  padding: 0.75em 0;
}

.calendar .body .cell {
  position: relative;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  transition: 0.25s ease-out;
}

@supports ((-webkit-backdrop-filter: blur(2px)) or (backdrop-filter: blur(2px))) {
  .calendar .body .cell {
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
  }
}

.calendar .body .cell:hover {
  background: var(--main-color);
  color: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .body .selected {
  background-color: var(--main-color);
  color: var(--bg-color);
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}

@font-face {
  font-family: 'Ubuntu';
  src: url('/fonts/Ubuntu-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('/fonts/Ubuntu-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('/fonts/Ubuntu-Light.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('/fonts/Ubuntu-LightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('/fonts/Ubuntu-Medium.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('/fonts/Ubuntu-MediumItalic.ttf');
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('/fonts/Ubuntu-Bold.ttf');
  font-weight: 900;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('/fonts/Ubuntu-BoldItalic.ttf');
  font-weight: 900;
  font-style: italic;
}

